@import 'assets/scss/variables';

:root {
  --bg-color: $background-gray;
  --txt-color: #757779;
}

[data-bs-theme='dark'] {
  --bg-color: #212529;
  --txt-color: #ffffffa6;
}

.menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-color);
  padding-left: 16px;
  border-radius: 8px;

  .menu-title {
    font-weight: $font-weight-bolder;
    color: $blue-100;
    text-transform: uppercase;

    .dropdown {
      display: inline-block;
      padding-left: $spacing-regular;
    }
  }

  .menu-items {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: var(--txt-color);
      text-transform: uppercase;
      font-size: 15px;
      padding: 8px 16px;
      font-weight: 600;
      text-align: center;

      &.active {
        color: $blue-100;
      }

      &:hover {
        color: $blue-100;
      }
    }
  }
}
