.config-section {
  .alert {
    font-size: 12px;
  }
}

.text-error {
  color: #df4a3e;
  font-size: 12px;
}

table.table-form {
  font-size: 12px;

  td.role-fields {
    width: 430px;
    min-width: 430px;
  }
  td.short-fields {
    width: 60px;
    text-align: center;
  }
}

.table-scrollable {
  max-height: 676px;
  overflow-y: auto;
}

.form-control {
  outline: 0;
  box-shadow: none !important;
}

.table-wrapped {
  height: 400px;
  overflow-y: auto;
}
