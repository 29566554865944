@import 'assets/scss/variables.scss';

#topic-container {
  position: absolute;
  left: 0;
  right: 0;
  top: -20px;

  .dark {
  }

  //&.modal-open {
  //  min-height: 180px;
  //}
  //
  //.mqb-mqb231 {
  //  background-color: red !important;
  //}
}

//.mqb-mqb231 {
//  background-color: red !important;
//}

.dark.mqb-mqb30 {
  background-color: #2a3439 !important;
}
