@import 'assets/scss/variables.scss';

.space-section {
  table td button {
    border: 0;
    font-size: 12px;
    outline: 0;
  }

  .table-responsive.vertical-scroll {
    height: 500px;
    overflow-y: auto;
  }

  .select__value-container {
    font-size: 12px;
  }

  .select__control {
    min-height: auto;
  }

  .select__indicator {
    padding: 4px;
  }
}

.estimate-table {
  .form-control {
    font-size: 12px;
  }

  input:focus,
  input:focus-visible {
    border: 1px solid $dark-blue;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

th.resource-name-cell {
  width: 320px;
}

.resource-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resource-section {
  .table thead tr th:nth-child(-n + 2),
  tbody tr td:nth-child(-n + 2) {
    text-align: center;
  }

  table thead th .select__control {
    border: 1px solid transparent;
  }
}

.badge {
  font-size: 10px;
  font-weight: 400;
  color: #fff;
  &.badge-open {
    background-color: #37b400;
  }

  &.badge-claimed {
    background-color: gray;
  }

  &.badge-claimed-owner {
    background-color: $dark-blue;
  }
}
.resource-popup {
  padding: 5px 10px;
  font-weight: 200;

  h6 {
    font-size: 12px;
    margin-bottom: 0;
    font-weight: bold;
  }
}
