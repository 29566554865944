@import 'assets/scss/variables';

.summary {
  .monthly-usage {
    padding-top: 16px;
    background-color: #ffc000ff;
    border-radius: 10px;
    color: white;
    font-weight: $font-weight-light;

    .title {
      font-size: 32px;
      margin: 0;
    }

    .usage {
      p.card-text {
        margin: 0 0 4px 0;
        text-align: right;
      }
    }
  }

  .top-billing,
  .time-cards,
  .table {
    .badge {
      font-size: 12px;
      margin: 0 auto;
      display: block;
      width: fit-content;

      &.Current {
        background-color: $blue-10 !important;
        color: $black-85;
      }

      &.Open {
        background-color: $green !important;
        color: $black-85;
      }

      &.Accept {
        background-color: $yellow !important;
        color: $black-85;
      }

      &.Dispute {
        background-color: $red !important;
      }

      &.Approve {
        background-color: $black-2 !important;
      }

      &.Resolve {
        background-color: $green !important;
      }

      &.Reject {
        background-color: $red !important;
      }
    }

    .red {
      background-color: $red;
      padding: 2px 6px;
      color: white;
      border: none;
    }

    .green {
      background-color: $green;
      padding: 2px 6px;
      border: none;
    }

    .grey {
      background-color: $black-2 !important;
      padding: 2px 6px;
    }
  }

  .time-cards {
    .red {
      margin-left: $spacing-large;
    }

    .card {
      max-height: 300px;
      overflow: hidden;

      .card-text {
        min-height: 200px;
      }
    }
  }

  .radio-toggle-switch-container {
    display: block;
    position: relative;
    z-index: 2;
    left: 26%;
    top: 14px;
    margin-bottom: 0px;
    max-width: 190px;

    .radio-toggle-switch {
      label {
        padding: 0px;
      }
    }
  }
}
