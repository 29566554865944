@import '../../../assets/scss/variables.scss';

.credit-form {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap;

  label {
    font-size: $font-size-regular;
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .field {
    flex-shrink: 0;
    flex-grow: 1;
  }

  .react-datepicker__input-container input {
    padding: 0.5rem 0.75rem 0.5rem 2rem !important;
    font-size: 12px;
  }

  .select__control--is-focused {
    box-shadow: none;
  }
}

.credit-modal {
  .table td button.btn,
  input {
    font-size: 12px;
  }

  .react-datepicker__input-container input {
    padding: 0.4rem 0.75rem 0.4rem 2rem !important;
  }

  .btn-actions {
    font-size: 12px;
  }

  .react-select {
    font-size: 12px;
  }
}
