.table.table-editable {
  tbody tr td {
    transition: border-color 0.3s ease;
  }

  tbody tr td:focus-within {
    outline: 2px solid $dark-blue;
    z-index: 999;
  }

  tbody tr td:has(> input) {
    padding: 0;
  }

  tbody tr td input {
    border-radius: 0;
    font-size: 12px;
    border-color: transparent;
    margin: 0;
  }

  tbody tr td input:disabled {
    background-color: transparent;
    border-color: transparent;
  }

  tbody tr td:has(> .react-select) {
    padding: 0;
  }

  tbody tr td .react-select {
    border: none;
    outline: 0 !important;
  }

  tbody tr td .react-select .select__control {
    border: none;
    outline: 0;
  }

  tbody tr td .react-select:focus {
    border: 0;
    outline: 0 !important;
  }

  tbody tr td .react-select .select__control .select__indicators {
    display: none;
  }

  *:focus {
    outline: none;
  }
}
