@import 'assets/scss/variables';
@import 'assets/scss/overrides';

@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

@import 'assets/scss/plugins/react-tabs-scrollable.scss';
@import 'assets/scss/plugins/table-editable.scss';

html {
  transition: color 0.5s ease, background-color 0.5s ease;
}

.table thead th {
  font-weight: 700;
}

.App {
  font-weight: $font-weight-normal;
  background-color: $background-gray;
  padding-bottom: 2.5rem;
  min-height: calc(100vh - 70px - 83px);
}

.alert-info {
  color: $dark-blue;
  background-color: #e3eeff;
  border-color: $dark-blue;
}

.alert-danger {
  color: $red;
  background-color: #fff9f9;
  border-color: $red;
}

.badge.bg-primary {
  background-color: $dark-blue !important;
}

.form-check-input:checked[type='checkbox'] {
  background-color: $dark-blue;
  border-color: $dark-blue;
  font-size: 18px;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

.slick-prev,
.slick-next {
  width: 0;
  height: 0;
  top: 40%;
  z-index: 1;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.slick-prev {
  border-right: 25px solid $dark-blue;
  left: -12px;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  border: none;
}

.slick-next {
  border-left: 25px solid $dark-blue;
  right: -12px;
}

.slick-prev::before,
.slick-next::before {
  content: none;
}

.section-gray {
  background-color: $blue-10;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
}

.text-sm {
  font-size: 12px;
}

.text-md {
  font-size: 14px;
}

.simple-card {
  border-radius: 8px;
  border: 1px solid #d0daea;
  padding: 1.4rem;
  margin-bottom: 1.2rem;
  padding: 10px;

  input,
  select {
    font-size: 12px;
  }
}

.btn.btn-primary {
  background-color: $dark-blue;
  border-color: $dark-blue;
}

.btn.btn-primary:disabled {
  background-color: rgba($dark-blue, 0.9);
  border-color: rgba($dark-blue, 0.9);
}

.btn-table {
  position: absolute;
  top: -4px;
  right: 4px;
}

.text-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.alert.alert-success {
  font-size: 14px;
  padding: 0.75rem 1.25rem;
}

.alert-dismissible .btn-close {
  padding: 1rem;
  box-shadow: none;
  outline: none;
}

.rts___tab.rts___btn.rts___tab___selected {
  background-color: $dark-blue;
}

.table-sticky-header.table-bordered > :not(caption) > * > * {
  border-width: 0;
}

.table.table-sticky-header {
  border-collapse: separate;
  border-spacing: 0;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    tr th {
      border-right: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6;

      &:first-child {
        border-left: 1px solid #dee2e6;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  tbody {
    tr td {
      border-bottom: 1px solid #dee2e6;
      border-right: 1px solid #dee2e6;
      vertical-align: middle;

      &:first-child {
        border-left: 1px solid #dee2e6;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}

.accordion {
  .accordion-header {
    .accordion-button {
      &:hover {
        box-shadow: none !important;
      }
    }
  }
}
.nav-tabs li .nav-link {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

tr.expanded-row > td {
  background-color: $dark-blue !important;
}

.tabs-section {
  .nav-link {
    color: $black-85;
    padding: $spacing-small $spacing-large;
  }

  .nav-link.active {
    color: $blue-100 !important;
    font-weight: $font-weight-bold;
    border-color: $gray-80;
    border-bottom: 1px solid transparent;
  }

  .tab-content {
    padding: $spacing-large !important;
    border: 1px solid $gray-80;
    border-top: 1px solid transparent;
    border-radius: 0px !important;
  }

  .nav-tabs {
    padding-top: $spacing-medium;
    border-bottom: 1px solid $gray-80;
    li {
      margin-bottom: 0px;

      .tab-item {
        color: $black-85;
        padding: $spacing-small $spacing-large;

        &.active {
          color: $blue-100;
          font-weight: $font-weight-bold;
          border-color: $gray-80;
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  margin-top: 2px;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 30px !important;
}

.info-badge {
  background-color: #dcdcdc;
  color: $dark-blue;
  font-size: 9px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: -4px;
  border: 0;
}

label.form-label {
  font-size: 14px;
}
.react-datepicker-wrapper {
  width: 100%;
}

.select__control {
  font-size: 12px;
  min-height: auto !important;

  .select__indicator {
    padding: 5px;
  }
}

.add-more-icon,
.remove-icon {
  background-color: transparent;
  border: none;
  right: $spacing-xl;
  width: $spacing-xl;
  height: $spacing-xl;
}

.remove-icon {
  color: $red;
}

.add-more-icon {
  color: $green;
}

@include color-mode(dark) {
  .App {
    background-color: #1b191b;
  }

  .top-nav {
    background-color: #212529;
  }

  .main .tab-content {
    background-color: #212529;
  }

  .menu-bar .menu-items .nav-item a .card {
    border-bottom: 2px solid #1c191a;
  }

  .table .green {
    color: #1b191b;
  }

  .space-section {
    background-color: #1c191a;
  }

  .pagination-controls {
    button {
      background-color: #212529;
    }
  }

  .expanded-row {
    & > td {
      background-color: #1c191a;
    }
  }

  .semi-circular-progressbar {
    circle {
      stroke: #6a6a6a;
    }
    .dot::before {
      border-color: #8d8d8d;
    }

    .circled-content {
      background-color: #6a6a6a;
    }
  }

  .circular-progressbar {
    circle {
      stroke: #6a6a6a;
    }
    .content {
      background-color: #6a6a6a;
    }
  }

  #budgets .card.card-budget {
    border: 2px solid #1c191a;
  }

  table {
    .current-month {
      background-color: #000711c2 !important;
    }
  }

  .tabs-section {
    .tab-content {
      border: 1px solid #495057;
      border-top: 1px solid transparent;
    }

    .nav-tabs {
      border-bottom: 1px solid #495057;
    }

    .nav-tabs li .nav-link {
      color: #adb5bd;
    }

    .nav-tabs li .nav-link.active {
      border-color: #495057;
      border-bottom: 1px solid transparent;
    }
  }

  .select__control {
    background-color: #212529;
    border: 1px solid #495057;
    font-size: 12px;
    min-height: auto;

    .select__indicator {
      padding: 5px;
    }

    &:hover {
      border-color: #495057;
    }

    .select__multi-value {
      background-color: #3c3c3c;
      .select__multi-value__label {
        color: $gray-80;
      }
    }
  }

  .select__single-value {
    color: #adb5bd;
  }

  .select__menu {
    background-color: #212529;
  }
  .select__option {
    background-color: #212529;
  }

  .select__option:hover {
    background-color: #3c3c3c;
  }

  .react-datepicker {
    background-color: #212529;
    border: 1px solid #495058;
  }

  .react-datepicker__header {
    background-color: #1b1b1b;
    border-bottom: 1px solid #1a1819;
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: #adb5bd;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: #adb5bd;
    }
  }

  .react-datepicker__triangle {
    &::before,
    &::after {
      border-bottom-color: #495058;
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #1b1b1b;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #495058;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    border-bottom: none;
    border-top-color: #212529;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    bottom: -1px;
    border-top-color: #495058;
  }

  .react-datepicker__day--disabled {
    color: #585858 !important;
  }

  .react-datepicker__day {
    color: white;

    &:hover {
      background-color: #3c3c3c;
    }
  }

  .react-datepicker__calendar-icon {
    fill: #adb5bd;
  }

  .select__input-container {
    color: #adb5bd;
  }

  .info-badge {
    background-color: #8d8d8d;
    color: #000;
  }

  .simple-card {
    border: 1px solid #494b4f;
  }

  .table.table-sticky-header {
    thead {
      tr th {
        border-right: 1px solid #495057;
        border-bottom: 1px solid #495057;
        border-top: 1px solid #495057;

        &:first-child {
          border-left: 1px solid #495057;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }

    tbody {
      tr td {
        border-bottom: 1px solid #495057;
        border-right: 1px solid #495057;
        vertical-align: middle;

        &:first-child {
          border-left: 1px solid #495057;
        }
      }
    }
  }
}

.table {
  font-size: 12px;
}

th button.sorting-button {
  width: 100%;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

th button.sorting-button svg {
  color: #b4b4b4;
}

.alert-dismissible .btn-close {
  padding: 1rem;
  box-shadow: none;
  outline: none;
}
